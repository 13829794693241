// **** variables
@import "variables.scss";

// **** mixins
@import "mixins/utility.scss";
@import "mixins/responsive.scss";

@mixin commonIeStyles {
  select::-ms-expand {
    display: none;
  }

  .overview,
  .specs {
    overflow: hidden;
  }

}

// ie 11
@media all and (-ms-high-contrast:none) {
  @include commonIeStyles;
  
  .page {
    display: block;
  }

  .header {
    &__sub {
      width: calc(33.33% - 1px);
      max-width: calc(33.33% - 1px);
    }
  }

  .articles {
    &__section_main &__col:first-child &__item {
      background: red;
    }
  }

  .articles {
    &__section_main &__col:first-child &__item {
      max-width: calc(33.33% - 16px);
    }
    &__section_main &__col:first-child &__item:nth-child(3n+1) {
      max-width: calc(66.66% - 16px);
    }
    &__bg {
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .trend {
    &__col {
      max-width: 50%;
    }
  }

  .showcase {
    &__stat {
      flex: none;
      &:nth-child(2n+1) {
        flex: none;
      }
    }
  }

  .perform {
    overflow: hidden;
    &__col {
      &:first-child {
        width: 100%;
        max-width: calc(100% - 488px);
      }
    }
  }

  .charts {
    &__col {
      max-width: 50%;
    }
  }

  .table-container {
    display: block;
    margin: 0;
    overflow: visible;
    &:before,
    &:after {
      display: none;
    }
  }
}

// ie edge
@supports (-ms-ime-align:auto) {
  @include commonIeStyles;  
}
