@media all and (-ms-high-contrast: none) {
  select::-ms-expand {
    display: none;
  }
  .overview,
  .specs {
    overflow: hidden;
  }
  .page {
    display: block;
  }
  .header__sub {
    width: calc(33.33% - 1px);
    max-width: calc(33.33% - 1px);
  }
  .articles__section_main .articles__col:first-child .articles__item {
    background: red;
  }
  .articles__section_main .articles__col:first-child .articles__item {
    max-width: calc(33.33% - 16px);
  }
  .articles__section_main .articles__col:first-child .articles__item:nth-child(3n+1) {
    max-width: calc(66.66% - 16px);
  }
  .articles__bg {
    overflow: hidden;
  }
  .articles__bg img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
  .trend__col {
    max-width: 50%;
  }
  .showcase__stat {
    flex: none;
  }
  .showcase__stat:nth-child(2n+1) {
    flex: none;
  }
  .perform {
    overflow: hidden;
  }
  .perform__col:first-child {
    width: 100%;
    max-width: calc(100% - 488px);
  }
  .charts__col {
    max-width: 50%;
  }
  .table-container {
    display: block;
    margin: 0;
    overflow: visible;
  }
  .table-container:before, .table-container:after {
    display: none;
  }
}

@supports (-ms-ime-align: auto) {
  select::-ms-expand {
    display: none;
  }
  .overview,
  .specs {
    overflow: hidden;
  }
}
